import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import FocusLock from 'react-focus-lock';
import {
  Dropdown,
  MessageModal,
  TextInput,
} from '@makeably/creativex-design-system';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  valueOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  existingCode: PropTypes.shape({
    code: PropTypes.string,
    valueId: PropTypes.number,
  }),
};

const defaultProps = {
  existingCode: {},
};

function CodeModal({
  existingCode,
  isOpen,
  onCancel,
  onSave,
  valueOptions,
}) {
  const [name, setName] = useState('');
  const [value, setValue] = useState(null);

  useEffect(() => {
    setName(existingCode.code || '');
    setValue(valueOptions.find((option) => option.value === existingCode.valueId) || null);
  }, [existingCode]);

  const editMode = !!existingCode.code;
  const title = editMode ? 'Rebind Code Value' : 'Add Code';
  const buttonLabel = editMode ? 'Update' : 'Add';
  const buttonDisabled = !name || !value || (editMode && existingCode.valueId === value.value);

  const handleSave = () => {
    setName('');
    setValue(null);
    onSave(name, value.value);
  };

  const handleCancel = () => {
    setName('');
    setValue(null);
    onCancel();
  };

  return (
    <FocusLock disabled={!isOpen} returnFocus>
      <MessageModal
        actionButtonDisabled={buttonDisabled}
        actionButtonLabel={buttonLabel}
        isOpen={isOpen}
        title={title}
        onActionButtonClick={handleSave}
        onClose={handleCancel}
      >
        <div className="u-flexRow u-gap-24">
          <TextInput
            disabled={editMode}
            label="Code Name"
            size="medium"
            value={name}
            onChange={(text) => setName(text)}
          />
          <Dropdown
            disabled={!name}
            label="Select Value"
            menuProps={{ size: 'medium' }}
            options={valueOptions}
            selected={value}
            size="medium"
            onChange={(option) => setValue(option)}
          />
        </div>
      </MessageModal>
    </FocusLock>
  );
}

CodeModal.propTypes = propTypes;
CodeModal.defaultProps = defaultProps;

export default CodeModal;
