import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Divider,
  Tag,
} from '@makeably/creativex-design-system';
import styles from './TypeCard.module.css';

const propTypes = {
  href: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  svg: PropTypes.string.isRequired,
  description: PropTypes.string,
  isBeta: PropTypes.bool,
};

const defaultProps = {
  description: '',
  isBeta: false,
};

const TypeCard = ({
  key,
  description,
  isBeta,
  label,
  svg,
  href,
}) => (
  <a key={key} href={href}>
    <Card className={styles.card} padding={false}>
      { isBeta && (
        <div className={styles.beta}>
          <Tag color="purple" label="Beta" />
        </div>
      ) }
      <img alt="Report Illustration" src={svg} />
      <Divider />
      <div className={styles.text}>
        <h5>{ label }</h5>
        <div className="t-caption-1">
          { description }
        </div>
      </div>
    </Card>
  </a>
);

TypeCard.propTypes = propTypes;
TypeCard.defaultProps = defaultProps;

export default TypeCard;
