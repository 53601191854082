import React from 'react';
import PropTypes from 'prop-types';
import { NULL_VALUE } from 'components/internal/shared';
import { valueIf } from 'utilities/object';
import {
  internalExplorerAssetPath,
  internalExplorerAuditPath,
  internalExplorerAuditAssetPath,
  internalExplorerAuditPostPath,
  internalExplorerCompanyPath,
  internalExplorerMarketPath,
  internalExplorerOrganizationBrandPath,
  internalExplorerUserPath,
} from 'utilities/routes';
import {
  toDate,
  toDateTime,
} from 'utilities/string';

export const recordProps = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    ),
    PropTypes.bool,
    PropTypes.number,
    PropTypes.shape({}),
    PropTypes.string,
  ]),
);

export const rolesProps = PropTypes.objectOf(PropTypes.bool);

function getNullDimension() {
  return {
    label: 'NULL',
    value: NULL_VALUE,
  };
}

// @note: replace null routes once added to the explorer
const routesByKey = {
  asset_id: internalExplorerAssetPath,
  audit_id: internalExplorerAuditPath,
  auditable_id: null,
  benchmark_category_id: null,
  audit_asset_id: internalExplorerAuditAssetPath,
  company_id: internalExplorerCompanyPath,
  guideline_id: null,
  guideline_detail_id: null,
  industry_id: null,
  inviter_id: internalExplorerUserPath,
  market_id: internalExplorerMarketPath,
  organization_id: internalExplorerCompanyPath,
  organization_affiliate_id: null,
  organization_brand_id: internalExplorerOrganizationBrandPath,
  parent_id: internalExplorerCompanyPath,
  primary_asset_id: internalExplorerAssetPath,
  primary_audit_post_id: internalExplorerAuditPostPath,
  reviewer_id: internalExplorerUserPath,
  thumbnail_id: null,
  user_id: internalExplorerUserPath,
};

function determineType(key, inputValue) {
  if (key.endsWith('_id')) return 'id';
  if (key.endsWith('_at')) return 'time';
  if (key.endsWith('_date')) return 'date';
  if (inputValue.startsWith?.('http')) return 'link';

  return null;
}

function getIdPath(key, value) {
  const route = routesByKey[key];

  return route?.(value);
}

function formatElement(key, value, type) {
  switch (type) {
    case 'id': {
      const path = getIdPath(key, value);
      return path ? <a href={getIdPath(key, value)}>{ value }</a> : null;
    }
    case 'link':
      return <a href={value}>{ value }</a>;
    default:
      return null;
  }
}

function formatLabel(value, type) {
  switch (type) {
    case 'array':
      return `[${value}]`;
    case 'bool':
      return value ? 'true' : 'false';
    case 'date':
      return toDate(new Date(value), true);
    case 'time':
      return toDateTime(new Date(value));
    default:
      return null;
  }
}

function formatValue(value, type) {
  switch (type) {
    case 'array':
      return `[${value}]`;
    default:
      return value;
  }
}

export function getDimension(key, inputValue) {
  if (inputValue === null) return getNullDimension();

  const type = determineType(key, inputValue);

  if (type) {
    const element = formatElement(key, inputValue, type);
    const label = formatLabel(inputValue, type);
    const value = formatValue(inputValue, type);

    return {
      ...valueIf(element, 'element', element),
      ...valueIf(label, 'label', label),
      value,
    };
  }

  return {
    value: JSON.stringify(inputValue),
  };
}

export function getRecordItem(record) {
  const keys = Object.keys(record).sort();

  return keys.reduce((obj, key) => ({
    ...obj,
    [key]: getDimension(key, record[key]),
  }), {});
}

export function renderResult({
  id,
  description,
  path,
  type,
}) {
  return (
    <a
      key={path}
      className="u-flexRow u-gap-8 u-marginBottom-16"
      href={path}
    >
      <div>{ id }</div>
      <div>{ type }</div>
      <div>{ description && `(${description})` }</div>
    </a>
  );
}
