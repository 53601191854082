import React from 'react';
import PropTypes from 'prop-types';
import PostInfo, { propTypes as postInfoPropTypes } from 'components/admin/review_queue/post_info/PostInfo';
import CheckDetails from 'components/internal/review/CheckDetails';
import { checkProps } from 'components/internal/review/shared';
import { editInternalReviewChallengePath } from 'utilities/routes';
import styles from './ChallengeDisplay.module.css';

const propTypes = {
  challenge: PropTypes.shape({
    check: checkProps.isRequired,
    id: PropTypes.number.isRequired,
    postInfo: PropTypes.shape(postInfoPropTypes).isRequired,
  }).isRequired,
  passed: PropTypes.bool.isRequired,
  onAssetReady: PropTypes.func.isRequired,
  onPassedChange: PropTypes.func.isRequired,
};

function ChallengeDisplay({
  challenge,
  passed,
  onAssetReady,
}) {
  // ??? remove
  console.log('PASSED', passed, typeof onPassedChange); // eslint-disable-line

  return (
    <div className={styles.main}>
      <PostInfo
        {...challenge.postInfo}
        onAssetReady={onAssetReady}
      />
      <div className="u-flexColumn u-gap-24">
        <CheckDetails
          check={challenge.check}
          url={editInternalReviewChallengePath(challenge.id)}
        />
        { /*
        <CheckGuideline
        />
        */ }
        <div style={{ whiteSpace: 'pre' }}>
          { JSON.stringify(challenge, null, 2) }
        </div>
      </div>
    </div>
  );
}

ChallengeDisplay.propTypes = propTypes;

export default ChallengeDisplay;

// import React from 'react';
// import classNames from 'classnames';
// import {
// CopyButton,
// Icon,
// Spinner,
// } from '@makeably/creativex-design-system';
// import EvaluationBanner from 'components/admin/review_queue/preflight/PreflightEvaluationBanner';
// import ReviewGuide, { guideProps } from 'components/internal/review_guides/ReviewGuide';
//
// export const checkProps = PropTypes.shape({
// brand: PropTypes.string.isRequired,
// channel: PropTypes.string.isRequired,
// customer: PropTypes.string.isRequired,
// definition: PropTypes.string.isRequired,
// description: PropTypes.string.isRequired,
// id: PropTypes.number.isRequired,
// isBranded: PropTypes.bool.isRequired,
// market: PropTypes.string.isRequired,
// reviewGuides: PropTypes.arrayOf(guideProps).isRequired,
// reviewability: PropTypes.string.isRequired,
// rule: PropTypes.string.isRequired,
// status: PropTypes.string.isRequired,
// type: PropTypes.string.isRequired,
// });
//
// export const propTypes = {
// check: checkProps.isRequired,
// isBusy: PropTypes.bool.isRequired,
// needsValidation: PropTypes.bool.isRequired,
// url: PropTypes.string.isRequired,
// onNeedsValidationChange: PropTypes.func.isRequired,
// onPassedChange: PropTypes.func.isRequired,
// disabled: PropTypes.bool,
// isPreflight: PropTypes.bool,
// passed: PropTypes.bool,
// showPreflightBanner: PropTypes.bool,
// onAssetReady: PropTypes.func,
// };
//
// const defaultProps = {
// disabled: false,
// isPreflight: false,
// onAssetReady: undefined,
// passed: null,
// showPreflightBanner: false,
// };

// function renderIcon(passed) {
// switch (passed) {
//     case true:
//       return <Icon color="green" name="checkCircle" noWrapper />;
//     case false:
//       return <Icon color="red" name="xCircle" noWrapper />;
//     default:
//       return <Icon color="orange" name="questionCircle" noWrapper />;
// }
// }
//
// function CheckDisplay({
// check,
// disabled,
// needsValidation,
// isBusy,
// isPreflight,
// passed,
// onAssetReady,
// onNeedsValidationChange,
// onPassedChange,
// url,
// }) {
// const {
//     definition,
//     description,
//     isBranded,
//     postInfo,
//     reviewGuides,
//     rule,
// } = check;
//
// const handleClick = () => {
//     if (needsValidation) {
//       onNeedsValidationChange(false);
//     } else {
//       onPassedChange(!passed);
//     }
// };
//
// const buttonClasses = classNames(
//     styles.evalButton,
//     { [styles.needsValidation]: needsValidation },
// );
//
// return (
//     <div className={styles.content}>
//       <div className={styles.postInfo}>
//       </div>
//       <div className={styles.data}>
//         <div className={styles.link}>
//           <a href={url} rel="noreferrer" target="_blank">Link</a>
//           <CopyButton text={url} />
//         </div>
//         <div className={styles.passed}>
//           <div>
//             <button
//               className={buttonClasses}
//               disabled={disabled || isBusy}
//               type="button"
//               onClick={handleClick}
//             >
//               { renderIcon(passed) }
//             </button>
//           </div>
//           <div>
//             <h5>{ `${rule}${definition ? '-' : ''}${definition ?? ''}` }</h5>
//             <div className={styles.description}>
//               <div className={styles.descriptionText}>
//                 { description }
//               </div>
//               <div>
//                 <CopyButton text={description} />
//               </div>
//             </div>
//           </div>
//         </div>
//         { isPreflight && isBranded && <EvaluationBanner /> }
//         { reviewGuides.length > 0 && (
//           <div className={styles.reviewGuides}>
//             { reviewGuides.map((guide) => (
//               <ReviewGuide
//                 key={guide.id}
//                 guide={guide}
//                 initialIsOpen
//               />
//             )) }
//           </div>
//         ) }
//       </div>
//       { isBusy && (
//         <div className={styles.busy}>
//           <div className={styles.spinner}>
//             <Spinner />
//           </div>
//         </div>
//       ) }
//     </div>
// );
// }
//
// CheckDisplay.propTypes = propTypes;
// CheckDisplay.defaultProps = defaultProps;
//
// export default CheckDisplay;
