import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Tabs,
} from '@makeably/creativex-design-system';
import ChallengeModal from 'components/internal/review/ChallengeModal';
import {
  emptyState,
  filterProps,
  getNullableDimension,
} from 'components/internal/shared';
import FilterTags from 'components/molecules/FilterTags';
import ItemsFilter from 'components/molecules/ItemsFilter';
import ItemsTable from 'components/molecules/ItemsTable';
import { getNextIndex } from 'utilities/array';
import { saveFilterQuery } from 'utilities/filtering';
import { getItemSortBy } from 'utilities/item';
import { generateItemButton } from 'utilities/itemElement';
import {
  filterItems,
  getValueOptions,
} from 'utilities/itemFilter';
import {
  getPage,
  getParams,
  setParam,
} from 'utilities/url';

const challengeProps = PropTypes.arrayOf(
  PropTypes.shape({
    assetId: PropTypes.number.isRequired,
    brandNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    campaignStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
    companyNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    id: PropTypes.number.isRequired,
    marketNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    timeRemaining: PropTypes.number.isRequired,
    userInfo: PropTypes.string.isRequired,
    guidelineName: PropTypes.string,
  }),
);

const propTypes = {
  escalated: challengeProps.isRequired,
  pending: challengeProps.isRequired,
  reviewed: challengeProps.isRequired,
  initialFilterSelections: filterProps,
  initialTabValue: PropTypes.string,
};

const defaultProps = {
  initialFilterSelections: {},
  initialTabValue: 'pending',
};

const filterDimensions = [
  {
    label: 'Brand',
    value: 'brand',
  },
  {
    label: 'Campaign Status',
    value: 'campaignStatus',
  },
  {
    label: 'Company',
    value: 'company',
  },
  {
    label: 'Guideline',
    value: 'guideline',
  },
  {
    label: 'Market',
    value: 'market',
  },
  {
    label: 'User',
    value: 'user',
  },
];

const headers = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'assetId',
    label: 'Asset ID',
  },
  {
    key: 'campaignStatus',
    label: 'Campaign Status',
  },
  {
    key: 'company',
    label: 'Company',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'timeRemaining',
    label: 'Time Remaining',
  },
];

function getTabs(escalated, pending, reviewed) {
  return [
    {
      challenges: pending,
      label: `Pending (${pending.length})`,
      value: 'pending',
    },
    {
      challenges: escalated,
      label: `Escalated (${escalated.length})`,
      value: 'escalated',
    },
    {
      challenges: reviewed,
      label: `Recently Reviewed (${reviewed.length})`,
      value: 'reviewed',
    },
  ];
}

function getSetValue(set) {
  switch (set.size) {
    case 0:
      return 'N/A';
    case 1:
      return set.values().next().value;
    default:
      return 'Multiple';
  }
}

function getArrayDimension(values) {
  const set = new Set(values);
  const value = getSetValue(set);

  return { value };
}

function getItems(challenges, onIdClick) {
  return challenges.map(({
    assetId,
    brandNames,
    campaignStatuses,
    companyNames,
    guidelineName,
    id,
    marketNames,
    timeRemaining,
    userInfo,
  }) => ({
    assetId: { value: assetId },
    brand: getArrayDimension(brandNames),
    campaignStatus: getArrayDimension(campaignStatuses),
    company: getArrayDimension(companyNames),
    guideline: getNullableDimension(guidelineName),
    id: {
      element: generateItemButton(id, () => onIdClick(id)),
      value: id,
    },
    market: getArrayDimension(marketNames),
    timeRemaining: { value: timeRemaining },
    user: { value: userInfo },
  }));
}

function Challenges({
  escalated: initialEscalated,
  initialFilterSelections,
  initialTabValue,
  pending: initialPending,
  reviewed,
}) {
  const params = getParams(window);
  const [escalated, setEscalated] = useState(initialEscalated);
  const [pending, setPending] = useState(initialPending);
  const [selectedTabValue, setSelectedTabValue] = useState(initialTabValue);
  const [items, setItems] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [filterSelections, setFilterSelections] = useState(initialFilterSelections);
  const [sort, setSort] = useState({
    key: 'assetId',
    asc: true,
  });
  const [page, setPage] = useState(getPage(params));
  const [evaluateId, setEvaluateId] = useState(null);
  const tabs = getTabs(escalated, pending, reviewed);
  const selectedTab = tabs.find(({ value }) => value === selectedTabValue);
  const isEscalated = selectedTab.value === 'escalated';
  const isPending = selectedTab.value === 'pending';

  useEffect(() => {
    const allItems = getItems(selectedTab.challenges, setEvaluateId);
    const options = getValueOptions(filterDimensions, allItems);

    setItems(allItems);
    setFilterOptions(options);
  }, [selectedTab.challenges]);

  const filteredItems = useMemo(() => (
    filterItems(items, filterSelections)
  ), [items, filterSelections]);

  const sortedItems = useMemo(() => {
    const byKeyDir = getItemSortBy(sort.key, sort.asc);
    return filteredItems.slice().sort(byKeyDir);
  }, [filteredItems, sort]);

  const handleFilterSelect = async (value) => {
    setPage(1);
    setFilterSelections(value);

    if (Object.keys(value).length > 0) {
      const uuid = await saveFilterQuery(value);
      setParam('filter_uuid', uuid, params, window);
    } else {
      setParam('filter_uuid', '', params, window);
    }
  };

  const handleTabClick = async (value) => {
    setSelectedTabValue(value);
    handleFilterSelect({});

    setParam('tab', value, params, window);
  };

  const handleChallengeComplete = (id) => {
    const index = sortedItems.findIndex((item) => item.id.value === id);
    const nextIndex = getNextIndex(index, sortedItems.length);
    if (isPending) {
      setPending((last) => last.filter((record) => record.id !== id));
    } else if (isEscalated) {
      setEscalated((last) => last.filter((record) => record.id !== id));
    }

    if (nextIndex >= 0) {
      setEvaluateId(sortedItems[nextIndex].id.value);
    } else {
      setEvaluateId(null);
      handleFilterSelect({});
    }
  };

  return (
    <>
      <Card className="u-flexColumn u-gap-24">
        <div className="u-flexRow u-alignCenter u-gap-8">
          <ItemsFilter
            dimensions={filterDimensions}
            isOpen={filterOpen}
            options={filterOptions}
            selections={filterSelections}
            onClose={() => setFilterOpen(false)}
            onOpen={() => setFilterOpen(true)}
            onSelect={handleFilterSelect}
          />
          <FilterTags
            dimensions={filterDimensions}
            selections={filterSelections}
            onClick={() => setFilterOpen(true)}
            onRemove={handleFilterSelect}
          />
        </div>
        <div>
          <Tabs
            currentTab={selectedTab?.label}
            tabs={tabs.map((tab) => ({
              ...tab,
              onClick: () => handleTabClick(tab.value),
            }))}
            variant="button"
          />
          <ItemsTable
            className="u-scrollShadowRight"
            emptyTableContent={emptyState}
            headers={headers}
            items={sortedItems}
            page={page}
            sort={sort}
            onPageChange={setPage}
            onSortChange={setSort}
          />
        </div>
      </Card>
      <ChallengeModal
        id={evaluateId}
        isEscalated={isEscalated}
        isPending={isPending}
        onClose={() => setEvaluateId(null)}
        onComplete={(id) => handleChallengeComplete(id)}
      />
    </>
  );
}

Challenges.propTypes = propTypes;
Challenges.defaultProps = defaultProps;

export default Challenges;
