# TODO: Replace AntdSelect
import React from "react"
import PropTypes from "prop-types"
import AntdSelect from "components/reusable/antd_select"
import ObjectSelectorForm from "components/audit/object_selector_form"
import { TextField } from  "@duik/it"

class AuditFilterInput extends React.Component
  @propTypes =
    allowSelectAll: PropTypes.bool
    isObjectSelectorForm: PropTypes.bool
    modalId: PropTypes.string
    onChange: PropTypes.func
    options: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ])
    placeholder: PropTypes.string
    searchByLabel: PropTypes.bool
    selected: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.number,
      PropTypes.string
    ])
    type: PropTypes.oneOf(
      [
        'brand_cue',
        'creatives',
        'custom_filters',
        'custom_segments',
        'dimension',
        'guideline',
        'metric',
        'regulatory',
        'search',
      ]
    ).isRequired

  @defaultProps =
    options: []
    searchByLabel: false

  disabled: ->
    !(@props.options.length > 0)

  selectMode: ->
    if @props.type in ['dimension', 'custom_filters', 'custom_segments']
      'multiple'
    else
      'default'

  handleTextChange: (event) =>
    @props.onChange(event.target.value)

  renderInput: ->
    if @props.type in ['metric', 'search', 'creatives']
      type = if @props.type is 'metric' then 'number' else 'text'
      `<TextField
        className='dimensionFilter-textInput'
        name={this.props.type + 'Input'}
        onChange={this.handleTextChange}
        placeholder={this.props.placeholder}
        value={this.props.selected || ''}
        type={type}
      />`

    else if @props.isObjectSelectorForm and !_.isArray(@props.options)
      `<ObjectSelectorForm
        modalId={this.props.modalId}
        onChange={this.props.onChange}
        selected={this.props.selected || []}
        {...this.props.options}
      />`

    else
      `<AntdSelect
        allowClear={false}
        allowSelectAll={this.props.allowSelectAll}
        className='dimensionFilter-multiSelect'
        disabled={this.disabled()}
        mode={this.selectMode()}
        size='small'
        options={this.props.options}
        onChange={this.props.onChange}
        selected={this.props.selected}
        searchByLabel={this.props.searchByLabel}
      />`

  render: ->
    `<div className='dimensionFilter-inputContainer'>
      {this.renderInput()}
    </div>`

export default AuditFilterInput
