import React from 'react';
import PropTypes from 'prop-types';
import TypeCard from 'components/molecules/TypeCard';
import { typeLabels } from 'components/reporting/shared';
import creativeLifecycleSvg from 'images/creativeLifecycleReport.svg';
import guidelineReportSvg from 'images/guidelineReport.svg';
import creativeQualitySvg from 'images/scoreReport.svg';
import timeBreakdownSvg from 'images/timeBreakdownReport.svg';
import { useViewPage } from 'utilities/mixpanel';
import { newReportingReportPath } from 'utilities/routes';
import styles from './SelectType.module.css';

const propTypes = {
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const reportTypeData = {
  ScoreReport: {
    description: 'Uncover insights from your creative data in real time using segments and metrics.',
    label: typeLabels.ScoreReport,
    svg: creativeQualitySvg,
  },
  TimePeriodReport: {
    description: 'Identify how your data changes over time using halves, quarters and months.',
    label: typeLabels.TimePeriodReport,
    svg: timeBreakdownSvg,
  },
  GuidelineReport: {
    description: 'Assess which guidelines are being met or missed to identify areas of improvement.',
    label: 'Guidelines',
    svg: guidelineReportSvg,
  },
  CreativeLifecycleReport: {
    description: 'Measure if and how your creative assets are being used globally.',
    label: typeLabels.CreativeLifecycleReport,
    svg: creativeLifecycleSvg,
  },
};

function SelectType({ types }) {
  useViewPage();

  return (
    <>
      <div className={styles.instructions}>
        <h5>Select the type of report you want to build:</h5>
      </div>
      <div className={styles.cards}>
        { types.map((type) => {
          const {
            description,
            isBeta,
            label,
            svg,
          } = reportTypeData[type] ?? {};

          return (
            <TypeCard
              key={type}
              description={description}
              href={newReportingReportPath({ type })}
              isBeta={isBeta}
              label={label}
              svg={svg}
            />
          );
        }) }
      </div>
    </>
  );
}

SelectType.propTypes = propTypes;

export default SelectType;
