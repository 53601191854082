// Utility functions used by report components
import React from 'react';
import {
  Tooltip,
  stringToDate,
} from '@makeably/creativex-design-system';
import {
  arrayIf,
  findObjectByValue,
  findObjectsByValue,
  objectValues,
  sortObjectArray,
} from 'utilities/array';
import { valueIf } from 'utilities/object';

export const FILTER_KEY = 'filter';
export const NONE_KEY = 'none';

export const noneHeader = {
  key: NONE_KEY,
  label: 'No Segments\nSelected',
  sortable: false,
};

export const indexHeader = {
  key: 'index',
  label: '#',
  sortable: false,
};

export function updateVizMetric(metric, selected) {
  if (metric && findObjectByValue(selected, metric)) {
    return metric;
  }
  return selected[0];
}

export function getHeaders(segments, metrics, vizMetric) {
  const useNone = (segments.length === 0) && (metrics.length > 0);
  const useIndex = segments.length > 1;
  const segmentHeaders = segments.map(({ label, value }) => ({
    key: value,
    label,
  }));
  const vizIndex = metrics.findIndex((metric) => metric.value === vizMetric?.value);
  const useViz = vizIndex !== -1;
  const vizHeader = {
    key: vizMetric?.value,
    label: vizMetric?.label,
    element: <Tooltip label={vizMetric?.tooltip}>{ vizMetric?.label }</Tooltip>,
    highlighted: true,
  };
  const before = metrics.slice(0, vizIndex);
  const after = metrics.slice(vizIndex + 1);
  const metricHeaders = [...before, ...after].map(({
    label, value, tooltip,
  }) => ({
    key: value,
    label,
    element: <Tooltip label={tooltip}>{ label }</Tooltip>,
  }));

  return [
    ...arrayIf(useNone, noneHeader),
    ...arrayIf(useIndex, indexHeader),
    ...segmentHeaders,
    ...arrayIf(useViz, vizHeader),
    ...metricHeaders,
  ];
}

const metricTooltips = {
  adoptionRate: 'Percentage of creatives that have met an individual guideline.',
  averageScore: 'The average of your organization’s scores.',
  lowQualitySpend: "Total spend behind assets that do not meet the applicable score's highest tier.",
  qualitySpend: "Total spend behind assets that meet the applicable score's highest tier.",
  qualitySpendRate: "The percentage of the spend behind assets meeting the score's highest tier threshold out of the total ad spend. All updated versions of a published asset are factored in.",
  scoreRate: "The percentage of assets meeting the score's highest tier threshold. All updated versions of a published asset are factored in.",
  totalAssets: 'All assets in CreativeX that are uploaded via Pre-flight or captured from an ad platform for In-Flight.',
  totalPosts: 'Total post count from assets captured by CreativeX.',
  totalSpend: 'Total spend from assets captured by CreativeX.',
};

const metricTypes = {
  adaptationRate: 'percent',
  averageScore: 'percent',
  lowQualitySpend: 'spend',
  qualitySpend: 'spend',
  qualitySpendRate: 'percent',
  scoreRate: 'percent',
  totalAssets: 'count',
  totalPosts: 'count',
  totalSpend: 'spend',
};

function getScoreMetric(score, key) {
  return {
    label: score.metricLabels?.[key] ?? key,
    tooltip: metricTooltips[key],
    type: metricTypes[key],
    value: `${key}::${score.versionId}`,
  };
}

export function getScoreMetrics(scores, canViewSpend) {
  return scores.reduce((arr, score) => (
    [
      ...arr,
      getScoreMetric(score, 'averageScore'),
      getScoreMetric(score, 'scoreRate'),
      ...arrayIf(
        canViewSpend, ...[
          getScoreMetric(score, 'qualitySpend'),
          getScoreMetric(score, 'lowQualitySpend'),
          getScoreMetric(score, 'qualitySpendRate'),
        ],
      ),
    ]
  ), []);
}

export function getGuidelineMetrics() {
  return {
    label: 'Adoption\nRate',
    tooltip: metricTooltips.adoptionRate,
    type: metricTypes.adaptationRate,
    value: 'adoptionRate',
  };
}

export function getMetrics(canViewSpend) {
  const commonMetrics = [
    {
      label: 'Total\nPosts',
      tooltip: metricTooltips.totalPosts,
      type: metricTypes.totalPosts,
      value: 'totalPosts',
    },
    {
      label: 'Total\nAssets',
      tooltip: metricTooltips.totalAssets,
      type: metricTypes.totalAssets,
      value: 'totalAssets',
    },
  ];

  const spendMetric = {
    label: 'Total\nSpend',
    tooltip: metricTooltips.totalSpend,
    type: metricTypes.totalSpend,
    value: 'totalSpend',
  };

  return [
    ...arrayIf(canViewSpend, spendMetric),
    ...commonMetrics,
  ];
}

function getScoreSegments(scores) {
  const segments = scores.map(({ name, versionId }) => ({
    label: `${name}: Tiers`,
    value: `rank::${versionId}`,
  }));

  return [
    {
      label: 'Scoring Tiers',
      group: true,
      value: 'scoringTiers',
    },
    ...sortObjectArray(segments, 'label'),
  ];
}

function getCustomSegments(names) {
  if (!names || names.length === 0) return [];

  const segments = names.map((name) => ({
    label: name,
    value: `customSegment::${name}`,
  }));

  return [
    {
      label: 'Custom Segments',
      group: true,
      value: 'customSegments',
    },
    ...sortObjectArray(segments, 'label'),
  ];
}

function getCustomFilterSegments(customFilters) {
  const customFilterSegmentValues = new Set();

  const segments = customFilters.reduce((arr, { id, name }) => {
    const value = `customFilter::${id}`;

    if (!customFilterSegmentValues.has(value)) {
      customFilterSegmentValues.add(value);
      arr.push({
        label: name,
        value,
      });
    }

    return arr;
  }, []);

  if (segments.length === 0) return [];

  return [
    {
      label: 'Custom Filters',
      group: true,
      value: 'customFilters',
    },
    ...sortObjectArray(segments, 'label'),
  ];
}

function getCommonSegments(additionalSegments) {
  const segments = [
    {
      label: 'Asset\nType',
      value: 'assetType',
    },
    {
      label: 'Brand',
      value: 'brand',
    },
    {
      label: 'Campaign Status',
      value: 'campaignStatus',
    },
    {
      label: 'Channel',
      value: 'channel',
    },
    {
      label: 'Content Type',
      value: 'contentType',
    },
    {
      label: 'Market',
      value: 'market',
    },
  ];
  if (additionalSegments) {
    segments.push(...additionalSegments);
    segments.sort((a, b) => a.label.localeCompare(b.label));
  }
  return [
    {
      label: 'General',
      group: true,
      value: 'general',
    },
    ...segments,
  ];
}

export function getScoresSegments(scores, customFilters, customSegments) {
  return [
    ...getCommonSegments([{
      label: 'Creative Agency',
      value: 'creativeAgency',
    },
    {
      label: 'Media Agency',
      value: 'mediaAgency',
    },
    {
      label: 'Partner',
      value: 'partner',
    },
    {
      label: 'Pre-Flight Submitter',
      value: 'submitter',
    },
    {
      label: 'Publisher',
      value: 'publisher',
    }]),
    ...getScoreSegments(scores),
    ...getCustomFilterSegments(customFilters),
    ...getCustomSegments(customSegments),
  ];
}

export function getGuidelinesSegments(scores, customFilters, customSegments) {
  return [
    ...getCommonSegments([{
      label: 'Guideline',
      value: 'guideline',
      disabled: true,
    }, {
      label: 'Score',
      value: 'scoreName',
    }]),
    ...getScoreSegments(scores),
    ...getCustomFilterSegments(customFilters),
    ...getCustomSegments(customSegments),
  ];
}

function getLabeledRanks(record, labeledScores) {
  return labeledScores.reduce((obj, { labelsByRank, versionId }) => {
    const key = `rank::${versionId}`;
    const value = record[key];
    const label = labelsByRank[value];

    return {
      ...obj,
      [key]: label,
    };
  }, {});
}

function getCustomFilterOptions(record, customFilters) {
  return customFilters.reduce((obj, filterOption) => {
    const {
      dimension,
      id,
      optionName,
      optionValues,
    } = filterOption;

    if (optionValues.includes(record[dimension])) {
      const key = `customFilter::${id}`;
      return {
        ...obj,
        [key]: optionName,
      };
    }

    return obj;
  }, {});
}

function getLabeledDates(record, dateLabels) {
  if (!dateLabels) return {};

  const {
    key,
    labels,
  } = dateLabels;
  const value = record.dateBucket;

  return {
    [key]: labels[value],
  };
}

export function preprocessRecords(rawRecords, scores, customFilters, dateLabels) {
  const labeledScores = scores.map(({ versionId, tiers }) => ({
    labelsByRank: tiers.reduce((obj, { label, rank }) => ({
      ...obj,
      [rank]: label,
    }), {}),
    versionId,
  }));

  return rawRecords.map((record) => ({
    ...record,
    ...getLabeledRanks(record, labeledScores),
    ...getCustomFilterOptions(record, customFilters),
    ...getLabeledDates(record, dateLabels),
  }));
}

export function calcPropertiesJson({
  selectedDateOption,
  selectedFilters,
  selectedMetrics,
  selectedSegments,
  selectedTimePeriod,
  sort,
  vizMetric,
}) {
  const properties = {
    endDate: selectedDateOption?.endDate,
    filters: selectedFilters,
    metrics: objectValues(selectedMetrics),
    segments: objectValues(selectedSegments),
    ...valueIf(sort, 'sort', sort),
    startDate: selectedDateOption?.startDate,
    ...valueIf(selectedTimePeriod, 'timePeriod', selectedTimePeriod?.value),
    ...valueIf(selectedDateOption?.type, 'type', selectedDateOption?.type),
    vizMetric: vizMetric?.value,
  };

  return JSON.stringify(properties);
}

function findDateOption(options, startDate, endDate) {
  if (!options) return null;

  const found = options.find((opt) => opt.startDate === startDate && opt.endDate === endDate);

  return found ?? options[0];
}

function findDateRange(customRangeProps, properties) {
  if (!customRangeProps) return null;

  const {
    endDate: maxDate,
    startDate: minDate,
  } = customRangeProps;

  const {
    endDate: savedEndDate,
    startDate: savedStartDate,
  } = properties;

  const withinMax = stringToDate(maxDate).getTime() >= stringToDate(savedEndDate).getTime();
  const withinMin = stringToDate(minDate).getTime() <= stringToDate(savedStartDate).getTime();

  return {
    endDate: withinMax ? savedEndDate : maxDate,
    startDate: withinMin ? savedStartDate : minDate,
    type: 'dynamic',
  };
}

export function parseProperties(properties, {
  customRangeProps,
  dateOptions,
  metrics,
  segments,
  timePeriods,
}) {
  return {
    ...valueIf(dateOptions, 'selectedDateOption', findDateOption(dateOptions, properties.startDate, properties.endDate)),
    selectedDateRange: findDateRange(customRangeProps, properties),
    selectedFilters: properties.filters ?? {},
    selectedMetrics: findObjectsByValue(metrics, properties.metrics),
    selectedSegments: findObjectsByValue(segments, properties.segments),
    ...valueIf(timePeriods, 'selectedTimePeriod', findObjectByValue(timePeriods, properties.timePeriod)),
    sort: properties.sort,
    useCustomDates: properties?.type === 'dynamic' && customRangeProps.customDatesEnabled,
    vizMetric: findObjectByValue(metrics, properties.vizMetric) ?? metrics[0],
  };
}
