# TODO: Replace AntdSelect
import React from "react"
import PropTypes from "prop-types"
import AntdSelect from "components/reusable/antd_select"
import AuditFilterInput from "components/audit/audit_filter_input"
import MixpanelTracker from "components/utils/MixpanelTracker"
import { Button as DuikButton } from  "@duik/it"
import { Icon } from '@makeably/creativex-design-system'

class AuditFilter extends React.Component
  # NB: This is the minimum of options in a filter to allow Select All behavior
  # and 10 seems reasonable at this time.
  # ex. we don't need a select all for a 3 item list
  MIN_ITEMS_FOR_SELECT_ALL = 10

  @propTypes =
    disabledOptions: PropTypes.array
    filtersForExports: PropTypes.bool
    id: PropTypes.number.isRequired
    inputName: PropTypes.string
    inputOptions: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ])
    onChange: PropTypes.func
    onRemove: PropTypes.func
    options: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ])
    searchByLabel: PropTypes.bool
    selected: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
    selectedInput: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
      PropTypes.number
    ])
    type: PropTypes.oneOf(
      [
        'brand_cue',
        'creatives',
        'custom_filters',
        'custom_segments',
        'dimension',
        'guideline',
        'metric',
        'regulatory',
        'search',
      ]
    )

  @defaultProps =
    filtersForExports: false
    searchByLabel: false
    type: 'dimension'

  handleChange: (type) =>
    (value) =>
      @props.onChange(@props.id, value, type)

  handleRemove: =>
    @props.onRemove(@props.id)

  hasSelection: ->
    isDefined = !_.isUndefined(@props.selectedInput)

    if @props.type is 'dimension'
      isDefined and @props.selectedInput.length > 0
    else
      isDefined

  allowSelectAll: ->
    @props.inputOptions.length > MIN_ITEMS_FOR_SELECT_ALL and
      @props.type is 'dimension' and
      @props.inputName isnt 'ad_account'

  isObjectSelectorForm: ->
    @props.selected is 'ad_account' || @props.selected is 'ad_campaign'

  renderHiddenInput: (inputName) ->
    (value, key) ->
      `<input key={key} type="hidden" name={inputName} value={value} />`

  renderHiddenInputs: ->
    return unless @props.selectedInput and @props.selectedInput.length > 0

    # NB: For dimension filter types, we do a performance improvement with the
    # values we return to the server
    if @props.type is 'dimension'
      values = @props.selectedInput
      baseInputName = @props.inputName

      # NB: Only do the optimization when we have a 'Select All' option
      if @allowSelectAll()
        # If everything is selected, send value 'all'
        if values.length is @props.inputOptions.length
          values = ['all']

        # If more than half the values are selected, pass the smaller set with an
        # exclude param
        else if values.length >= (@props.inputOptions.length / 2)
          baseInputName = baseInputName + "_ex"
          allValues = _.pluck(@props.inputOptions, 'value')
          values = _.difference(allValues, values)

      inputName = "dimension[#{baseInputName}][]"

    else if @props.type is 'metric'
      inputName = "dimension[#{@props.inputName}][]"
      values = [@props.selectedInput]

    else if @props.type in ['search', 'creatives']
      inputName = "dimension[#{@props.inputName}]"
      values = [@props.selectedInput]

    else if @props.type is 'custom_filters'
      inputName = "dimension[custom_filters][]"
      values = @props.selectedInput

    else if @props.type is 'custom_segments'
      inputName = "dimension[custom_segments][#{@props.inputName}][]"
      values = @props.selectedInput

    else # regulatory / guideline / brand cue filters
      inputName = "dimension[#{@props.type}_#{@props.selectedInput}][]"
      # NB: We use the selected value of the filterType dropdown
      #     because we want to pass the ID of the guideline as the
      #     value to the server, not the value of passed/failed
      values = [@props.selected]

    `<span>
      {values.map(this.renderHiddenInput(inputName))}
    </span>`

  trackAddFilter: =>
    properties =  {
      filterType: @props.filterType
      filter: @props.selected,
      selectedInput: @props.selectedInput,
    }
    MixpanelTracker.trackFilter('Add Audit Filter', properties)

  renderAddButton: =>
    return unless @hasSelection()
    return if @props.filtersForExports

    btnProps =
      className: 'dimensionFilter-saveButton'
      type: "submit"
      value: "Submit"

    `<DuikButton onClick={this.trackAddFilter} sm {...btnProps}>Add</DuikButton>`

  renderRemoveButton: ->
    btnProps =
      className: 'dimensionFilter-actionButton dimensionFilter-removeButton'
      onClick: @handleRemove
      type: 'button'

    `<DuikButton sm square {...btnProps}>
      <Icon name='closeX' color='darkPurple' noWrapper />
    </DuikButton>`

  render: ->
    spacer = 'is'
    if @props.type is 'metric'
      spacer = '  '
      placeholder = if @props.inputName.includes('impressions') then "# Impressions" else "$ USD"

    # Mark options as disabled that are selected in other filters
    _.each(
      @props.options,
      (option_group) =>
        _.each(
          option_group.filters,
          (filter) =>
            filter.disabled = @props.disabledOptions.includes(filter.value)
        )
    )

    `<div className='dimensionFilter'>
      {this.renderHiddenInputs()}
      <AntdSelect
        allowClear={false}
        className='dimensionFilter-primarySelect'
        dropdownClassName='dimensionFilter-primaryDropdown'
        mode='default'
        onChange={this.handleChange('filterType')}
        options={this.props.options}
        placeholder='Select...'
        selected={this.props.selected}
        size='small'
      />
      <div className="dimensionFilter-spacer">{spacer}</div>
      <AuditFilterInput
        allowSelectAll={this.allowSelectAll()}
        isObjectSelectorForm={this.isObjectSelectorForm()}
        modalId={'ObjectsFiltersForm-' + this.props.id}
        onChange={this.handleChange('filterValue')}
        options={this.props.inputOptions}
        placeholder={placeholder}
        searchByLabel={this.props.searchByLabel}
        selected={this.props.selectedInput}
        type={this.props.type}
      />
      <div className='dimensionFilter-actionButtonContainer'>
        {this.renderAddButton()}
        {this.renderRemoveButton()}
      </div>
    </div>`

export default AuditFilter
