import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import FocusLock from 'react-focus-lock';
import {
  MessageModal,
  TextInput,
} from '@makeably/creativex-design-system';

function generateHeaderText(type, isEditMode) {
  if (type === 'segment') {
    if (isEditMode) {
      return {
        title: 'Edit Segment',
        subtitle: 'This update will be reflected wherever this segment is used',
      };
    }
    return {
      title: 'Create New Segment',
      subtitle: 'You will be able to add the values that belong to this segment afterwards',
    };
  } else if (type === 'value') {
    if (isEditMode) {
      return {
        title: 'Edit Value',
        subtitle: 'This update will be reflected wherever this value is used',
      };
    }
    return {
      title: 'Create New Value',
      subtitle: 'This value will be added to the selected segment',
    };
  }
  return {
    title: '',
    subtitle: '',
  };
}

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['segment', 'value']).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  existingValue: PropTypes.string,
};

const defaultProps = {
  existingValue: '',
};

function SegmentModal({
  isOpen,
  onCancel,
  onSave,
  type,
  existingValue,
}) {
  const [name, setName] = useState('');

  useEffect(() => {
    setName(existingValue || '');
  }, [existingValue]);

  const label = (type === 'segment') ? 'Segment Name' : 'Value Name';
  const isEditMode = !!existingValue;
  const { title, subtitle } = generateHeaderText(type, isEditMode);
  const saveLabel = isEditMode ? 'Update' : 'Save';
  const buttonDisabled = !name || (isEditMode && name === existingValue);

  const handleSave = () => {
    setName('');
    onSave(name, isEditMode);
  };

  const handleCancel = () => {
    setName('');
    onCancel();
  };

  return (
    <FocusLock>
      <MessageModal
        actionButtonDisabled={buttonDisabled}
        actionButtonLabel={saveLabel}
        isOpen={isOpen}
        subtitle={subtitle}
        title={title}
        onActionButtonClick={handleSave}
        onClose={handleCancel}
      >
        <TextInput
          label={label}
          size="medium"
          value={name}
          onChange={(text) => setName(text)}
        />
      </MessageModal>
    </FocusLock>
  );
}

SegmentModal.propTypes = propTypes;
SegmentModal.defaultProps = defaultProps;

export default SegmentModal;
