import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Button,
  Card,
  Dropdown,
  Icon,
  MoreButton,
} from '@makeably/creativex-design-system';
import ChannelLogos from 'components/molecules/ChannelLogos';
import ItemsTable from 'components/molecules/ItemsTable';
import { addToast } from 'components/organisms/Toasts';
import { setItemElement } from 'utilities/itemElement';
import { track } from 'utilities/mixpanel';
import { patch } from 'utilities/requests';
import {
  newTaxonomyCustomTaxonomyPath,
  editTaxonomyCustomTaxonomyPath,
  changeStateTaxonomyCustomTaxonomyPath,
} from 'utilities/routes';

const ruleProps = PropTypes.shape({
  active: PropTypes.bool,
  channels: PropTypes.arrayOf(PropTypes.string),
  delimiter: PropTypes.string,
  id: PropTypes.number,
  key: PropTypes.string,
  position: PropTypes.number,
  segmentName: PropTypes.string,
});

const propTypes = {
  channelLabelMap: PropTypes.objectOf(PropTypes.string).isRequired,
  rules: PropTypes.arrayOf(ruleProps).isRequired,
};

const headers = [
  {
    key: 'segmentName',
    label: 'Custom Segment Name',
  },
  {
    key: 'indicator',
    label: 'Key/Position',
  },
  {
    key: 'delimiter',
    label: 'Delimiter',
  },
  {
    key: 'channels',
    label: 'Channels',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'actions',
    label: '',
  },
];

async function changeStatus(id) {
  const response = await patch(changeStateTaxonomyCustomTaxonomyPath(id));
  const result = response.data;
  addToast(result.message, { type: result.success ? 'success' : 'error' });
  window.location.reload();
}

function getActions({
  active, id,
}) {
  const options = [{
    label: 'Edit',
    url: editTaxonomyCustomTaxonomyPath(id),
  }];
  if (active) {
    options.push({
      label: 'Deactivate',
      onClick: () => changeStatus(id),
    });
  } else {
    options.push({
      label: 'Activate',
      onClick: () => changeStatus(id),
    });
  }
  return (
    <MoreButton
      menuSize="small"
      options={options}
    />
  );
}

function getChannels(channels, channelLabelMap) {
  const channelItems = channels.map((channel) => ({
    label: channelLabelMap[channel],
    value: channel,
  }));

  return {
    element: (
      <ChannelLogos channels={channelItems} shownMax={5} />
    ),
    value: '',
  };
}

function getStatus(active) {
  return {
    element: (
      <Icon color={active ? 'green' : 'red'} name={active ? 'checkCircle' : 'xCircle'} />
    ),
    value: active ? 'active' : 'inactive',
  };
}

function getItems(rules, channelLabelMap) {
  return rules.map((rule) => setItemElement({
    id: { value: rule.id },
    channels: getChannels(rule.channels, channelLabelMap),
    delimiter: { value: rule.delimiter },
    actions: {
      element: getActions(rule),
      value: rule.id,
    },
    status: getStatus(rule.active),
    indicator: { value: rule.key || rule.position },
    segmentName: { value: rule.segmentName },
  }));
}

function getRuleOptions(rules) {
  const options = rules.map((rule) => (rule.key ? 'Key Based' : 'Position Based'));
  return Array.from(new Set(options)).sort().map((option) => ({
    label: option,
    value: option,
  }));
}

function getStatusOptions(rules) {
  const options = rules.map((rule) => (rule.active ? 'Active' : 'Inactive'));
  return Array.from(new Set(options)).sort().map((option) => ({
    label: option,
    value: option,
  }));
}

function getChannelOptions(rules, channelLabelMap) {
  const channels = rules.reduce((acc, rule) => {
    rule.channels.forEach((channel) => {
      acc.add(channel);
    });
    return acc;
  }, new Set());
  return Array.from(channels).sort().map((channel) => ({
    label: channelLabelMap[channel],
    value: channel,
  }));
}

function Rules({
  channelLabelMap,
  rules,
}) {
  const [ruleTypeFilter, setRuleTypeFilter] = useState(undefined);
  const [channelFilter, setChannelFilter] = useState(undefined);
  const [statusFilter, setStatusFilter] = useState(undefined);
  const [filteredRules, setFilteredRules] = useState(rules);
  const items = useMemo(
    () => getItems(filteredRules, channelLabelMap), [filteredRules, channelLabelMap],
  );

  track('view_custom_taxonomy_configuration');

  const hasFilters = ruleTypeFilter || channelFilter || statusFilter;

  useEffect(() => {
    const filtered = rules.filter((rule) => {
      const ruleTypeMatch = !ruleTypeFilter || (rule.key ? 'Key Based' : 'Position Based') === ruleTypeFilter.value;
      const channelMatch = !channelFilter || rule.channels.includes(channelFilter.value);
      const statusMatch = !statusFilter || (rule.active ? 'Active' : 'Inactive') === statusFilter.value;
      return ruleTypeMatch && channelMatch && statusMatch;
    });
    setFilteredRules(filtered);
  }, [ruleTypeFilter, channelFilter, statusFilter]);

  const dropDownOptions = useMemo(() => ({
    ruleTypeOptions: getRuleOptions(rules),
    channelOptions: getChannelOptions(rules, channelLabelMap),
    statusOptions: getStatusOptions(rules),
  }), [rules, channelLabelMap]);

  const handleClearFilters = () => {
    setRuleTypeFilter(undefined);
    setChannelFilter(undefined);
    setStatusFilter(undefined);
  };

  return (
    <Card>
      <div className="u-flexRow u-justifyBetween u-marginBottom-16 u-alignCenter">
        <div className="u-flexRow u-gap-16 u-alignEnd">
          <Dropdown
            disabled={dropDownOptions.ruleTypeOptions.length === 0}
            label="Rule Type"
            menuProps={{ size: 'medium' }}
            options={dropDownOptions.ruleTypeOptions}
            selected={ruleTypeFilter}
            size="small"
            onChange={(value) => setRuleTypeFilter(value)}
          />
          <Dropdown
            disabled={dropDownOptions.channelOptions.length === 0}
            label="Channel"
            menuProps={{ size: 'medium' }}
            options={dropDownOptions.channelOptions}
            selected={channelFilter}
            size="small"
            onChange={(value) => setChannelFilter(value)}
          />
          <Dropdown
            disabled={dropDownOptions.statusOptions.length === 0}
            label="Status"
            menuProps={{ size: 'medium' }}
            options={dropDownOptions.statusOptions}
            selected={statusFilter}
            size="small"
            onChange={(value) => setStatusFilter(value)}
          />
          { hasFilters && (
            <Button
              label="Clear"
              variant="tertiary"
              onClick={handleClearFilters}
            />
          ) }
        </div>
        <AddNewButton
          label="Add New"
          url={newTaxonomyCustomTaxonomyPath()}
        />
      </div>
      <ItemsTable
        headers={headers}
        items={items}
      />
      {
        filteredRules.length === 0 && (
          <div className="u-textCenter u-marginTop-16">
            No available taxonomy structures
          </div>
        )
      }
    </Card>
  );
}

Rules.propTypes = propTypes;

export default Rules;
