import React from 'react';
import PropTypes from 'prop-types';
import {
  CopyButton,
  MaxWidthText,
} from '@makeably/creativex-design-system';
import { checkProps } from 'components/internal/review/shared';
import styles from './CheckDetails.module.css';

const propTypes = {
  check: checkProps.isRequired,
  url: PropTypes.string.isRequired,
};

const details = [
  {
    key: 'company',
    label: 'Company',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'id',
    label: 'Guideline Check ID',
  },
  {
    key: 'auditAssetId',
    label: 'CreativeX ID',
  },
  {
    key: 'channel',
    label: 'Channel',
  },
  {
    key: 'campaignStatus',
    label: 'Campaign Status',
  },
  {
    key: 'reviewability',
    label: 'Reviewability',
  },
  {
    key: 'state',
    label: 'State',
  },
];

function renderDetail(key, label, check) {
  return (
    <div key={key} className={styles.detail}>
      <div className="t-overline">{ label }</div>
      <h5>
        <MaxWidthText
          text={check?.[key] ?? 'N/A'}
        />
      </h5>
    </div>
  );
}

function CheckDetails({ check, url }) {
  return (
    <div>
      <div className={styles.details}>
        { details.map(({ key, label }) => renderDetail(key, label, check)) }
      </div>
      <div className="u-flexRow u-alignCenter">
        <a href={url} rel="noreferrer" target="_blank">Link</a>
        <CopyButton text={url} />
      </div>
    </div>
  );
}

CheckDetails.propTypes = propTypes;

export default CheckDetails;
