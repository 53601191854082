import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  BasicModal,
  Button,
  CloseButton,
  Tooltip,
} from '@makeably/creativex-design-system';
import ChallengeDisplay from 'components/internal/review/ChallengeDisplay';
import LoadingDisplay from 'components/internal/review/LoadingDisplay';
import { addErrorToast } from 'components/organisms/Toasts';
import { get } from 'utilities/requests';
import { evaluationPropsInternalReviewChallengePath } from 'utilities/routes';

const propTypes = {
  isEscalated: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  id: PropTypes.number,
};

const defaultProps = {
  id: undefined,
};

async function patchEvaluated(id, passed) {
  console.log('PATCH-EVALUATED', id, passed); // eslint-disable-line
  await new Promise((res) => setTimeout(res, 2000));

  // ??? update patchEvaluated with correct params
  //
  // const formData = new FormData();
  // formData.append('id', id);
  // formData.append('passed', passed);
  // formData.append('review_start_at', reviewStartAt);
  //
  // const url = adminReviewGuidelineCheckPath(guidelineId, id);
  // const response = await fetch(url, {
  //     method: 'PATCH',
  //     body: formData,
}

async function postEscalate(id) {
  console.log('POST-ESCALATE', id); // eslint-disable-line
  await new Promise((res) => setTimeout(res, 2000));

  // ??? update postEscalate with correct params
}

// ??? use or remove processCheckData, or move to shared
// export function processCheckData(check) {
// return {
//     ...check,
//     brand: handleMultiple(check.brands),
//     channel: handleMultiple(check.channels),
//     market: handleMultiple(check.markets),
//     reviewability: getReviewabilityLabel(check.unreviewableReason),
//     status: STATUS_NAMES[check.status],
//     type: check.pretests[0] ? 'Pre-Flight' : 'In-Flight',
// };
// }

// ??? add permission bools
function ChallengeModal({
  id,
  isEscalated,
  isPending,
  onClose,
  onComplete,
}) {
  const [challenge, setChallenge] = useState(null);
  const [passed, setPassed] = useState(false);
  const [isBusy, setIsBusy] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const isLoaded = Boolean(challenge);

  useEffect(() => {
    const updateChallenge = async () => {
      const { data, isError } = await get(evaluationPropsInternalReviewChallengePath(id));

      if (!isError) {
        setChallenge(data);
      } else {
        addErrorToast(`Could not load challenge ${id}`);
      }
    };

    setIsBusy(false);
    setIsReady(false);
    setChallenge(null);
    if (id) updateChallenge(id);
  }, [id]);

  useEffect(() => {
    setPassed(challenge?.passed);
  }, [challenge?.passed]);

  const handleClose = () => {
    setChallenge(null);
    onClose();
  };

  const handleEscalate = async () => {
    setIsBusy(true);
    await postEscalate(id);
    setChallenge(null);
    onComplete(id);
    setIsBusy(false);
  };

  const handleEvaluate = async () => {
    setIsBusy(true);
    await patchEvaluated(id, passed);
    setChallenge(null);
    onComplete(id);
    setIsBusy(false);
  };

  // ??? disable buttons if no permission
  const renderControls = () => (
    <div className="u-flexRow u-alignCenter u-gap-8">
      <Button
        label="Close"
        variant="tertiary"
        onClick={handleClose}
      />
      { isPending && (
        <>
          <Tooltip label="Escalate if you are unsure of a score" />
          <Button
            disabled={isBusy || !isReady}
            label="Escalate"
            variant="secondary"
            onClick={handleEscalate}
          />
        </>
      ) }
      { (isPending || isEscalated) && (
        <Button
          disabled={isBusy || !isReady}
          label="Evaluate"
          onClick={handleEvaluate}
        />
      ) }
    </div>
  );

  return (
    <BasicModal
      footer={renderControls()}
      header={<CloseButton onClick={handleClose} />}
      isOpen={Boolean(id)}
      onClose={handleClose}
    >
      { isLoaded && (
        <ChallengeDisplay
          challenge={challenge}
          passed={passed}
          onAssetReady={() => setIsReady(true)}
          onPassedChange={setPassed}
        />
      ) }
      { !isLoaded && <LoadingDisplay /> }
    </BasicModal>
  );
}

ChallengeModal.propTypes = propTypes;
ChallengeModal.defaultProps = defaultProps;

export default ChallengeModal;
