import React from 'react';
import { Spinner } from '@makeably/creativex-design-system';
import styles from './LoadingDisplay.module.css';

function LoadingDisplay() {
  return (
    <div className={styles.loader}>
      <Spinner />
      <h4>Loading...</h4>
    </div>
  );
}

export default LoadingDisplay;
