import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Dropdown,
  MultipleDropdown,
  RadioGroup,
  TextInput,
  Tooltip,
  toggleObject,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import TaxonomyTestModal, {
  brandCodeProps,
  marketCodeProps,
} from 'components/taxonomies/TaxonomyTestModal';
import {
  addFormDataArray,
  addFormDataObject,
  getAuthenticityToken,
  patch,
  post,
} from 'utilities/requests';
import {
  taxonomyCompanyTaxonomiesPath,
  taxonomyCompanyTaxonomyPath,
  taxonomyCustomTaxonomiesPath,
  taxonomyCustomTaxonomyPath,
} from 'utilities/routes';
import styles from './TaxonomyConfiguration.module.css';

const taxonomyProps = {
  channels: PropTypes.arrayOf(PropTypes.string).isRequired,
  delimiter: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  ruleType: PropTypes.string.isRequired,
  brandIndicator: PropTypes.string,
  marketIndicator: PropTypes.string,
};

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

const propTypes = {
  brandCodes: PropTypes.arrayOf(brandCodeProps).isRequired,
  channelOptions: PropTypes.arrayOf(optionProps).isRequired,
  marketCodes: PropTypes.arrayOf(marketCodeProps).isRequired,
  isCustom: PropTypes.bool,
  segmentOptions: PropTypes.arrayOf(optionProps),
  taxonomy: PropTypes.shape(taxonomyProps),
};

const defaultProps = {
  isCustom: false,
  segmentOptions: [],
  taxonomy: undefined,
};

const KEY_OPTION = 'keyBased';
const POSITION_OPTION = 'positionBased';
const EXAMPLE_KEY_TAXONOMY = 'ID~XXXX_MK~XXXX_AR~XXXX_MB~XXXX_CG~XXXX_AS~XXXX_OB~XXXX_FM~XXXX_MT~XXXX_GR~XXXX_LG~XXXX_FF~XXXX_SA~XXXX';
const EXAMPLE_POSITION_TAXONOMY = 'Region_Brand_Campaign Type_Campaign Name_Objective_Subobjective_GA Channel_Strategy Type_Audience List Type_StartDate';

function getPaths(isCustom) {
  if (isCustom) {
    return {
      createPath: taxonomyCustomTaxonomiesPath(),
      updatePath: (id) => taxonomyCustomTaxonomyPath(id),
    };
  }
  return {
    createPath: taxonomyCompanyTaxonomiesPath(),
    updatePath: (id) => taxonomyCompanyTaxonomyPath(id),
  };
}

function keyLabel() {
  return (
    <div className="u-flexColumn u-marginTop-16">
      Key Based
      <div className="t-caption-1">
        Values and their codes are deciphered by a key, regardless of position in the string
      </div>
    </div>
  );
}

function positionLabel() {
  return (
    <div className="u-flexColumn u-marginTop-16">
      Position Based
      <div className="t-caption-1">
        Values and their codes are deciphered by their position in a string
      </div>
    </div>
  );
}

const radioOptions = [
  {
    label: keyLabel(),
    value: KEY_OPTION,
  },
  {
    label: positionLabel(),
    value: POSITION_OPTION,
  },
];

async function submitTaxonomy(
  url,
  method,
  {
    channels,
    delimiter,
    brandIndicator,
    marketIndicator,
    type,
  },
) {
  const formData = new FormData();
  formData.append('authenticity_token', getAuthenticityToken());
  channels.forEach((channel) => formData.append('channels[]', channel));
  formData.append('delimiter', delimiter);

  if (type === KEY_OPTION) {
    formData.append('brand_delimiter', brandIndicator);
    formData.append('market_delimiter', marketIndicator);
  } else {
    formData.append('brand_position', brandIndicator);
    formData.append('market_position', marketIndicator);
  }

  const response = await fetch(url, {
    method,
    body: formData,
  });
  return response.json();
}

async function submitCustomTaxonomy(url, isUpdate, taxonomy) {
  const formData = new FormData();

  const data = {
    custom_dimension_id: taxonomy.selectedSegment.value,
    delimiter: taxonomy.delimiter,
  };
  if (taxonomy.type === KEY_OPTION) {
    data.key = taxonomy.customIndicator;
  } else {
    data.position = taxonomy.customIndicator;
  }
  addFormDataObject(formData, 'taxonomy', data);
  addFormDataArray(formData, 'channels', taxonomy.channels);

  let response;
  if (!isUpdate) {
    response = await post(url, formData);
  } else {
    response = await patch(url, formData);
  }

  return response.data;
}

async function updateTaxonomy({ id, ...rest }, isCustom) {
  if (isCustom) {
    return submitCustomTaxonomy(getPaths(isCustom).updatePath(id), true, rest);
  }

  return submitTaxonomy(getPaths(isCustom).updatePath(id), 'PATCH', rest);
}

async function createTaxonomy(rule, isCustom) {
  if (isCustom) {
    return submitCustomTaxonomy(getPaths(isCustom).createPath, false, rule);
  }

  return submitTaxonomy(getPaths(isCustom).createPath, 'POST', rule);
}

function getSegment(segmentId, segmentOptions) {
  return segmentOptions.find((opt) => opt.value === segmentId) ?? {};
}

function TaxonomyConfiguration({
  brandCodes,
  channelOptions,
  segmentOptions,
  marketCodes,
  taxonomy,
  isCustom,
}) {
  const initialChannels = channelOptions.filter((opt) => taxonomy?.channels.includes(opt.value));

  const [type, setType] = useState(taxonomy?.ruleType ?? KEY_OPTION);
  const [channelSelections, setChannelSelections] = useState(initialChannels);
  const [brandIndicator, setBrandIndicator] = useState(taxonomy?.brandIndicator);
  const [marketIndicator, setMarketIndicator] = useState(taxonomy?.marketIndicator);
  const [customIndicator, setCustomIndicator] = useState(taxonomy?.indicator ?? '');
  const [delimiter, setDelimiter] = useState(taxonomy?.delimiter);
  const [showTestModal, setShowTestModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(
    getSegment(taxonomy?.segmentId, segmentOptions),
  );

  const keyBased = type === KEY_OPTION;

  // Check if brand position overlaps with market position
  const overlappingIndicators = brandIndicator && brandIndicator === marketIndicator;
  const incompleteIndicators = isCustom ? !customIndicator : !(brandIndicator || marketIndicator);
  const incompleteForm = channelSelections.length === 0
    || overlappingIndicators
    || incompleteIndicators
    || !delimiter;

  useEffect(() => {
    const indicatorType = keyBased ? 'Keys' : 'Positions';

    if (overlappingIndicators) {
      addToast(`Brand and Market ${indicatorType} cannot be the same`, { type: 'error' });
    }
  }, [brandIndicator, marketIndicator]);

  const toggleType = () => {
    setType(keyBased ? POSITION_OPTION : KEY_OPTION);
    setBrandIndicator('');
    setMarketIndicator('');
  };

  const handleSave = async () => {
    setIsSubmitting(true);

    const data = {
      brandIndicator,
      channels: channelSelections.map((selection) => selection.value),
      customIndicator,
      delimiter,
      id: taxonomy?.id,
      marketIndicator,
      selectedSegment,
      type,
    };

    let response;
    if (data.id) {
      response = await updateTaxonomy(data, isCustom);
    } else {
      response = await createTaxonomy(data, isCustom);
    }

    if (response) {
      if (response.message) {
        addToast(response.message, { type: response.success ? 'success' : 'error' });
      } else {
        addToast('Taxonomy Structure Successfully Saved');
      }
      window.location.href = getPaths(isCustom).createPath;
    } else {
      addToast('An error occurred while saving the taxonomy', { type: 'error' });
      setIsSubmitting(false);
    }
  };

  const renderDelimiterLabel = () => (
    <div className="u-flexRow u-gap-8 u-alignCenter">
      Delimiter
      <Tooltip label="Character(s) used to separate sections of a campaign string" />
    </div>
  );

  const renderKeyOptions = () => {
    if (isCustom) {
      return (
        <>
          <Dropdown
            label="Custom Segment"
            menuProps={{ size: 'large' }}
            options={segmentOptions}
            selected={selectedSegment}
            size="large"
            onChange={(opt) => setSelectedSegment(opt)}
          />
          <TextInput
            label={`${keyBased ? 'Key' : 'Position'}`}
            name="market_indicator"
            placeholder={keyBased ? 'Add Key' : 'Select'}
            type={keyBased ? 'text' : 'number'}
            value={customIndicator}
            onChange={(key) => setCustomIndicator(key)}
          />
        </>
      );
    }
    return (
      <>
        <TextInput
          isError={overlappingIndicators}
          label={`Brand ${keyBased ? 'Key' : 'Position'}`}
          name="brand_indicator"
          placeholder={keyBased ? 'Add Key' : 'Select'}
          type={keyBased ? 'text' : 'number'}
          value={brandIndicator}
          onChange={(key) => setBrandIndicator(key)}
        />
        <TextInput
          isError={overlappingIndicators}
          label={`Market ${keyBased ? 'Key' : 'Position'}`}
          name="market_indicator"
          placeholder={keyBased ? 'Add Key' : 'Select'}
          type={keyBased ? 'text' : 'number'}
          value={marketIndicator}
          onChange={(key) => setMarketIndicator(key)}
        />
      </>
    );
  };

  return (
    <>
      <Card>
        <h5 className="u-marginBottom-8">Taxonomy Structure Type</h5>
        <div className="u-marginBottom-16">
          <RadioGroup
            name="typeSelectionRadioGroup"
            options={radioOptions}
            value={type}
            horizontal
            onChange={() => toggleType()}
          />
        </div>
        <div className={classNames(styles.example, 't-body-2', 'u-marginBottom-16')}>
          { `Example: ${keyBased ? EXAMPLE_KEY_TAXONOMY : EXAMPLE_POSITION_TAXONOMY}` }
        </div>
        <Divider />
        <div className="u-flexColumn u-marginTop-16 u-gap-16">
          <MultipleDropdown
            label="Applicable Channels"
            menuProps={{ size: 'large' }}
            options={channelOptions}
            selected={channelSelections}
            size="large"
            onChange={(opt) => setChannelSelections(toggleObject(channelSelections, opt))}
          />
          { renderKeyOptions() }
          <TextInput
            label={renderDelimiterLabel()}
            name="delimiter"
            placeholder="Add Delimiter"
            value={delimiter}
            onChange={(key) => setDelimiter(key)}
          />
          <div className="u-flexRow u-marginBottom-16">
            <Button
              disabled={incompleteForm}
              label="Test"
              variant="tertiary"
              onClick={() => setShowTestModal(true)}
            />
          </div>
        </div>
        <Divider />
        <div className="u-flexRow u-marginTop-16 u-justifyEnd">
          <Button
            disabled={incompleteForm || isSubmitting}
            label="Save"
            onClick={handleSave}
          />
        </div>
      </Card>
      <TaxonomyTestModal
        brandCodes={brandCodes}
        brandIndicator={brandIndicator}
        customIndicator={customIndicator}
        customSegment={selectedSegment}
        delimiter={delimiter}
        isOpen={showTestModal}
        keyBased={keyBased}
        marketCodes={marketCodes}
        marketIndicator={marketIndicator}
        onClose={() => setShowTestModal(false)}
      />
    </>
  );
}

TaxonomyConfiguration.propTypes = propTypes;
TaxonomyConfiguration.defaultProps = defaultProps;

export default TaxonomyConfiguration;
