import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import FocusLock from 'react-focus-lock';
import { MessageModal } from '@makeably/creativex-design-system';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['change', 'delete']).isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

function WarningModal({
  isOpen,
  onCancel,
  onConfirm,
  variant,
}) {
  const buttonLabel = variant === 'change' ? 'Change' : 'Delete';
  const title = variant === 'change' ? 'Change Code?' : 'Delete Code?';

  return (
    <FocusLock>
      <MessageModal
        actionButtonLabel={buttonLabel}
        actionButtonVariant="destructive"
        isOpen={isOpen}
        title={title}
        onActionButtonClick={onConfirm}
        onClose={onCancel}
      >
        Current and future creatives will no longer be mapped to the value assigned to this code.
        This will affect your company&apos;s data.
      </MessageModal>
    </FocusLock>
  );
}

WarningModal.propTypes = propTypes;

export default WarningModal;
