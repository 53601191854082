import React from 'react';
import PropTypes from 'prop-types';
import TypeCard from 'components/molecules/TypeCard';
import customSegmentSvg from 'images/customSegments.svg';
import mbmmSvg from 'images/mbmm.svg';
import { useViewPage } from 'utilities/mixpanel';
import {
  taxonomyCompanyTaxonomiesPath,
  taxonomySegmentOptionsPath,
  taxonomyUnparsableCampaignsPath,
} from 'utilities/routes';
import styles from 'components/reporting/SelectType.module.css';

const propTypes = {
  canManageMbmm: PropTypes.bool.isRequired,
};

function getSvg(type) {
  switch (type) {
    case 'CustomTaxonomy':
      return customSegmentSvg;
    case 'BrandMarketTaxonomy':
      return mbmmSvg;
    default:
      return '';
  }
}

function getCompanyTaxonomyPath(canManageMbmm) {
  return canManageMbmm ? taxonomyCompanyTaxonomiesPath() : taxonomyUnparsableCampaignsPath();
}

function getTaxonomyTypes(canManageMbmm) {
  return [
    {
      key: 'BrandMarketTaxonomy',
      description: 'Report on your brands and markets by setting up a taxonomy rule to search for them in your campaigns',
      label: 'Multi-Brand / Multi-Market',
      url: getCompanyTaxonomyPath(canManageMbmm),
    },
    {
      key: 'CustomTaxonomy',
      description: 'Create your own reporting fields based on the categories used by your company to segment your campaigns',
      label: 'Custom Reporting',
      url: taxonomySegmentOptionsPath(),
    },
  ];
}

function SelectType({ canManageMbmm }) {
  useViewPage();

  const typeData = getTaxonomyTypes(canManageMbmm);

  return (
    <>
      <div className={styles.instructions}>
        <h5>Select which taxonomies to view</h5>
      </div>
      <div className={styles.cards}>
        { typeData.map((type) => (
          <TypeCard
            key={type.key}
            description={type.description}
            href={type.url}
            label={type.label}
            svg={getSvg(type.key)}
          />
        )) }
      </div>
    </>
  );
}

SelectType.propTypes = propTypes;

export default SelectType;
